<template>
  <div>  
    <b-row>
      <b-col class="col-12 col-md-6 col-lg-3 d-flex align-items-stretch">
        <b-card class="overflow-hidden card-shadow">
          <div class="background-left-proposal default-color-background-tools">
          </div>
          <h6>{{$t('creator.all_pitches')}}</h6>
          <h2>{{ summary_casting.total_pitches }}</h2>
          <p class="text-muted m-0">-- {{$t('creator.textInAdmin')}}</p>
        </b-card>
      </b-col>

      <b-col class="col-12 col-md-6 col-lg-3 d-flex align-items-stretch">
        <b-card class="overflow-hidden card-shadow">
          <div class="background-left-proposal default-color-background-orders">
          </div>
          <h6>{{$t('creator.active_pitches')}}</h6>
          <h2>{{ summary_casting.active_pitches }}</h2>
          <p class="text-muted m-0">-- {{$t('creator.working_marketers')}}</p>
        </b-card>
      </b-col>

      <b-col class="col-12 col-md-6 col-lg-3 d-flex align-items-stretch">
        <b-card class="overflow-hidden card-shadow">
          <div class="background-left-proposal default-color-background-requests">
          </div>
          <h6>{{$t('creator.outstanding_pitches')}}</h6>
          <h2>--</h2>
          <p class="text-muted m-0">{{ summary_casting.draft_pitches }} {{$t('creator.in_draft_mode')}}</p>
        </b-card>
      </b-col>

      <b-col class="col-12 col-md-6 col-lg-3 d-flex align-items-stretch">
        <b-card class="overflow-hidden card-shadow">
          <div class="background-left-proposal default-color-background-earnings">
          </div>
          <h6>{{$t('creator.potencial_earnings')}}</h6>
          <h2>$ {{ numberFormat(potential_earnings, true) }}</h2>
          <p class="text-muted m-0">-- {{$t('creator.between_casting_calls')}}</p>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-12">
        <b-card class="table-responsive" id="table-searcher-card">
          <b-col class="d-flex justify-content-between align-items-center flex-wrap p-1">
            <div class="container-mode-views">
              <div class="color-gray-button responsive-button" v-if="checked_pitches.length !== 0">
                <span class="ml-2">{{capitalize($t('creator.selected_pitches'))}}: {{checked_pitches.length}}</span>
              </div>
            </div>
            <div class="d-flex align-items-center flex-wrap ml-2 mb-1 mb-sm-0">
              <b-button  variant="outline-secondary" class="button-filters" @click="open_sidebar = true"><feather-icon icon="FilterIcon"/> {{$t('search.filters')}}</b-button>
              <b-button 
                :disabled="filtersApply()"
                variant="outline-secondary"
                class="button-filters"
                @click="clearFilters()"
              >
                <feather-icon icon="Trash2Icon"/>
                {{$t('creator.clearFilter')}}

              </b-button>
            </div>
          </b-col>         
        </b-card>
      </b-col>
      <b-col>
        <div class="container-loader-searcher loader-table-cards" v-if="!loader_pitches">
          <b-spinner class="spinner-table-searcher"></b-spinner>
        </div>
        <div v-if="info_table.length > 0" class="scrollable-table">
          <b-table
            :items="info_table"
            id="table-searcher"
            :fields="$t(`creator.table_proposals.pitches`)"
            head-variant="light"
            @row-clicked="rowTableClick"
            :current-page="current_page"
            :tbody-tr-class="rowClass"
            :per-page="per_page"
          >
            <template #head(id)>
              <b-form-checkbox v-model="selected_all"></b-form-checkbox>
            </template>
            <template #cell(id)="data">
              <div class="position-relative">
                <div class="d-flex justify-content-between position-absolute">
                  <b-form-checkbox
                    class="checkbox-table-searcher d-none position-absolute-checkbox-searcher"
                    v-model="checked_pitches"
                    :value="data.item.uuid"
                    :ref="`checkbox_table_${data.index}`"
                  >
                  </b-form-checkbox>
                  <h6 class="id-table-searcher" :ref="`id_table_${data.index}`">{{data.index + 1}}</h6>
                </div>
              </div>
            </template>
            <template #cell(casting_call)="data">
              <div class="d-flex align-items-center" :id="'tooltip-alert-campaign-' + data.item.casting_call.uuid">
                <b-avatar  
                  button
                  variant="ligth"
                  :src="img_cover(data.item)"
                >
                </b-avatar>
                <div class="ml-1">
                  <span>{{data.item.casting_call.title}}</span>
                  <p class="text-muted m-0">
                    {{$t('creator.type_of_service').find(response => response.value === data.item.casting_call.content_type).text}}
                  </p>
                </div>
                <b-tooltip v-if="data.item.casting_call.status !== 'published'" :target="'tooltip-alert-campaign-' + data.item.casting_call.uuid" triggers="hover" placement="top">{{ $t('creator.campaign_not_available') }}</b-tooltip>
              </div>
            </template>
            <template #cell(connections)="data">
              <div class="d-flex align-items-center">
                <b-avatar-group :overlap="data.item.user_networks.length === 1 ? '' : '0.5'" variant="light" size="22">
                  <b-avatar button v-for="(item, index) in data.item.user_networks" 
                    :id="'tooltip-network-' + data.item.casting_call.uuid + '-' + item.uuid"
                    :icon="item.network"
                    :class="getLocalNetworkName(item.network)"
                    :key="index">
                    <b-img :src="getIconWhite(item.network)" :class="getIconColor(item.network, false)" fluid></b-img>
                  <b-tooltip :target="'tooltip-network-' + data.item.casting_call.uuid + '-' + item.uuid" triggers="hover" placement="top"> @{{ item.username }}</b-tooltip>
                </b-avatar>
               </b-avatar-group>
              </div>
            </template>
            <template #cell(status)="data">
              <div>
                <b-badge :class="colorStatus(data.item.status)">
                  {{$t('creator.status_pitch').find(response => response.value === data.item.status).text}}
                </b-badge>
              </div>
            </template>
            <template #cell(desired_payment)="data">
              <div>
                $ {{numberFormat(parseInt(data.item.desired_payment), true)}}
              </div>
            </template>
            <template #cell(date_submitted)="data">
              <div>
                {{data.item.date_submitted ? getDatev2(new Date(data.item.date_submitted)) : '--'}}
              </div>
            </template>
            <template #cell(actions)="data">
              <span>
                <b-dropdown
                  :hidden="data.item.status === 'rejected' || data.item.casting_call.status !== 'published'"
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                  dropright
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="editAction(data.item)">
                    <feather-icon icon="EditIcon" class="mr-50" />
                    <span>{{$t('lists.edit')}}</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="deletePitch(data.item)" v-if="data.item.status !== 'submitted'">
                    <feather-icon icon="XSquareIcon" class="mr-50" />
                    <span>{{$t('lists.delete')}}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </template>
          </b-table>
          <b-pagination 
            v-model="current_page" 
            :total-rows="info_table.length" 
            :per-page="per_page"
            class="d-flex justify-content-center"
          ></b-pagination>
        </div>
        <no-Results
          v-else
          :title= "$t('creator.no_results_pitches')"
          :show_button= "false"
        />
      </b-col>
    </b-row>
    <b-sidebar v-model="open_sidebar" right shadow backdrop bg-variant="white" class="sidebar-pitches-filter" header-class="container-title-sider">
      <template #header>
        <div class="w-100 d-flex justify-content-between align-items-center">
          <span>{{ $t('creator.filterPitch') }}</span>
          <feather-icon 
            icon="XIcon" 
            size="18" 
            class="close-sidebar-icon"
            @click="open_sidebar = false"
          >
          </feather-icon>
        </div>
      </template>
      <div class="p-1">
        <h5 class="mb-1 d-block text-muted">{{ $t('creator.statusFilter') }}</h5>
        <b-form-checkbox 
          v-for="(checkbox, index) in $t('creator.status_pitch')" 
          :key="index"
          class="mb-05" 
          v-model="statuses"
          :value="checkbox.value"
        >{{checkbox.text}}</b-form-checkbox>

        <h5 class="d-block mt-2 mb-1 text-muted">{{ $t('creator.compensationStep') }}</h5>
        <div class="d-flex mb-2">
          <b-form-group
            :label="$t('creator.minAmount')"
            label-for="min-compensation"
          >
            <cleave
              id="min-compensation"
              class="form-control"
              :options="cleaveOptions"
              placeholder="$ 10,000"
              v-model="compensation_min"
            />
          </b-form-group>
          <b-form-group
          :label="$t('creator.maxAmount')"
            label-for="max-compensation"
            class="ml-1"
          >
            <cleave
              id="max-compensation"
              class="form-control"
              :options="cleaveOptions"
              placeholder="$ 10,000"
              v-model="compensation_max"
            />
          </b-form-group>
        </div>

        <h5 class="d-block mt-2 mb-1 text-muted">{{ $t('creator.submittedAt') }}</h5>
        <div class="mb-2">
          <b-form-group
            :label="$t('creator.startDate')"
            label-for="start-date"
          >
            <b-form-datepicker id="start-date" v-model="date_init"/>
          </b-form-group>
          <b-form-group
          :label="$t('creator.endDate')"
            label-for="end-date"
          >
            <b-form-datepicker id="end-date" v-model="date_end"/>
          </b-form-group>
        </div>
      </div>
      <template #footer>
        <div class="button-spacing">
          <b-button
            class="btn-clear"
            variant="outline-secondary"
            @click="clearFilters"
          >
            <b-icon icon="trash" class="cursor-pointer" />
          </b-button>
          <b-button
            class="size-button"
            variant="primary"
            @click="applyFilters"
          >
            {{ $t("creator.updateResultFilters") }}
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BTooltip,
  BImg,
  BSpinner,
  BCol,
  BRow,
  BCard,
  BTable,
  BFormCheckbox,
  BFormGroup,
  BBadge,
  BAvatar,
  BAvatarGroup,
  BButton,
  BDropdown,
  BDropdownItem,
  BSidebar,
  BFormDatepicker,
  BPagination,
  BIcon,
} from 'bootstrap-vue';
import Cleave from 'vue-cleave-component'
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');
import casting_calls_services from '@/services/casting_calls';
import { getIconWhite, getIconColor, getLocalNetworkName } from '@/libs/utils/icons';
import { numberFormat, capitalize, decimalFixed } from '@/libs/utils/formats'
import { getDatev2 } from '@/libs/utils/times';
import { getUserData } from '@/libs/utils/user'
import { getAssetUrl } from '@/libs/utils/urls';

export default {
  name: 'pitches',
  components: {
    BIcon,
    BTooltip,
    BImg,
    BSpinner,
    BButton,
    BCard,
    BCol,
    BFormGroup,
    BRow,
    BTable,
    BSidebar,
    BFormDatepicker,
    BFormCheckbox,
    BBadge,
    BAvatar,
    BAvatarGroup,
    BDropdown,
    BDropdownItem,
    BPagination,
    Cleave,    
    noResults: () => import('@/views/pages/proposals/stepper/noResults.vue'),
  },
  data() {
    return {
      decimalFixed,
      getAssetUrl,
      getUserData,
      capitalize,
      getDatev2,
      numberFormat,
      getLocalNetworkName,
      getIconWhite,
      getIconColor,
      loader_pitches: false,
      selected_all: null,
      checked_pitches: [],
      info_table: [],
      open_sidebar: false,
      cleaveOptions: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        rawValueTrimPrefix: true,
      },
      user: getUserData(),
      statuses: [],
      compensation_min: '',
      compensation_max: '',
      date_init: '',
      date_end: '',
      current_page: 1,
      per_page: 10,
      summary_casting: {},
      potential_earnings: 0
    }
  },
  created() {
    this.getPitches({});
    this.getSummaryCreatorCasting()
  },
  methods: {
    img_cover(data) {
      return data.casting_call.cover_url ? data.casting_call.cover_url : getAssetUrl(data.casting_call.cover_image)
    },
    getSummaryCreatorCasting() {
      casting_calls_services.getSummaryCreatorCasting().then((response) => {
        this.summary_casting = response
        this.potential_earnings = decimalFixed(this.summary_casting.potential_earnings, 2)
      })
    },
    filtersApply() {
      return this.statuses.length === 0 && this.compensation_min === '' && this.compensation_max === '' && this.date_init === '' && this.date_end === ''
    },
    editAction(data) {
      this.$router.push({ name: 'stepper-send-pitch', params: {slug: data.casting_call.slug, step: 'preview', pitch_uuid: data.uuid, is_editing: true}})
    },
    colorStatus(status) {
      if (status === 'draft') return 'class-bd-draft'
      if (status === 'submitted') return "class-bd-submited";
      if (status === 'approved') return "class-bd-approved";
      if (status === 'rejected') return "class-bd-rejected";
      if (status === 'finished') return "class-bd-finished";
      return 'class-bd-finished'
    },
    getPitches(data) {
      this.loader_pitches = false
      casting_calls_services.getPitches(data).then((response) => {
        this.info_table = response.results;
        this.loader_pitches = true
      })
    },
    rowClass(data) {
      if (data.casting_call.status !== 'published')
        return 'row_disabled'
    },

    rowTableClick(data) {
      if (data.casting_call.status === 'published') {
        const url = `${window.origin}/view-casting-call/${data.casting_call.slug}`;
        window.open(url, '_blank');
      }
    },
    applyFilters() {
      const query = {
        statuses: this.statuses,
        compensation_min: this.compensation_min.length > 0 ? this.compensation_min : undefined, 
        compensation_max: this.compensation_max.length > 0 ? this.compensation_max : undefined,
        date_init: this.date_init.length > 0 ? this.date_init : undefined,
        date_end: this.date_end.length > 0 ? this.date_end : undefined,
      }
      this.getPitches(query);
      loadToastificationContent().then((component) => {
        this.$toast({
          component: component.default,
          position: 'top-right',
          props: {
            title: this.$t("creator.filterApply"),
            icon: "CheckCircleIcon",
            variant: "success",
            text: this.$t("creator.textfilter"),
          }
        })
      })
      this.open_sidebar = false
    },
    clearFilters() {
      this.statuses = [];
      this.compensation_max = '';
      this.compensation_min = '';
      this.compensation_max = '';
      this.date_init = '';
      this.date_end = '';
      this.getPitches({});
      this.open_sidebar = false;
    },
    checkCheckboxClasses(val) {
      if (val.length > 0) {
        for (let i = 0; i < this.info_table.length; i++) {
          this.$nextTick(() => {
            const checkbox = this.$refs[`checkbox_table_${i}`];
            const idTable = this.$refs[`id_table_${i}`];
            
            if (checkbox && checkbox.$el) {
              checkbox.$el.classList.remove('d-none');
              checkbox.$el.classList.remove('checkbox-table-searcher');
            }
            if (idTable) idTable.classList.add('d-none');
          });
        }
      } else {
        for (let i = 0; i < this.info_table.length; i++) {
          this.$nextTick(() => {
            const checkbox = this.$refs[`checkbox_table_${i}`];
            const idTable = this.$refs[`id_table_${i}`];
            
            if (checkbox && checkbox.$el) {
              checkbox.$el.classList.add('d-none');
              checkbox.$el.classList.add('checkbox-table-searcher');
            }            
            if (idTable) idTable.classList.remove('d-none');
          });
        }
      }
    },
    checkedAll() {
      this.checked_pitches = [];
      let in_array = 0;
      this.info_table.forEach((item, index) => {
        if ((in_array < this.per_page) && (index <= (this.per_page * this.current_page) && index >= (this.per_page * (this.current_page - 1)))) {
          this.checked_pitches.push(item.uuid)
          in_array += 1
        }
      });
    },
    deletePitch(data) {
      this.$swal({
        title: this.$t('creator.delete_pitches_title'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('creator.confirm_button_remove'),
        customClass: {
          confirmButton: 'btn confirm-button',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false,
        heightAuto: false
      }).then(result => {
        if (result.value) {
          casting_calls_services.deletePitch(data.uuid).then(() => {
            this.info_table = this.info_table.filter((info) => info.uuid !== data.uuid);
            loadToastificationContent().then((component) => {
              this.$toast({
                component: component.default,
                position: 'top-right',
                props: {
                  title: this.$t('creator.deleted_pitches'),
                  icon: "AlertCircleIcon",
                  variant: "danger",
                  text: this.$t('creator.deleted_pitches_text'),
                }
              })
            })
          });
        }
      });
    }
  },
  watch: {
    checked_pitches(val) {
      this.checkCheckboxClasses(val);
      if (this.checked_pitches.length === 0) this.selected_all = false
      else if (this.checked_pitches.length === this.info_table.length) this.selected_all = true
    },
    selected_all(val) {
      if (val) this.checkedAll();
      else this.checked_pitches = [];
    },
    current_page() {
      this.selected_all = null;
    }
  }
}
</script>
<style scoped>  .button-filters {
    margin-left: 5px;
  }
  .button-filters:focus {
    margin-left: 5px;
    background-color: transparent !important;
  }
  .mb-05 {
    margin-bottom: 0.5em;
  }
  .budget-input-sign {
    position: absolute;
    left: 1em;
    top: 0em;
    color: gray;
  }
  .close-sidebar-icon:hover {
    background-color: rgba(219, 219, 219, 0.5);
    border-radius: 50%;
    cursor: pointer;
  }

  .class-bd-draft {
    border: 1px solid rgba(255, 159, 67, 0.12);
    background-color: rgba(255, 159, 67, 0.12);
    color: #ff9f43 ;
    padding: 0.25em 0.6em;
  }

  .class-bd-submited {
    border: 1px solid #93e3fa;
    background-color: #bef3fc;
    color: #0050c5;
    padding: 0.25em 0.6em;
  }

.class-bd-approved {
  border: 1px solid #95ee6e;
  background-color: #c6f9ae;
  color: #0b6515;

}

.class-bd-rejected {
  border: 1px solid #ffc9cb;
  background-color: #ffc9cb;
  color: #f13e49;
}

.class-bd-finished {
  border: 1px solid #9E9E9E;
  background-color: #F5F5F5;
  color: #757575;
}
</style>
<style>
  .row_disabled{
    background-color: #b8babd29 !important;
  }
  .sidebar-pitches-filter > .b-sidebar {
    width: 400px !important;
  }
 .container-loader-searcher {
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: rgba(255, 255, 255);
    z-index: 1000;
    right: 5px;
  }
  .loader-table-cards {
    z-index: 10;
    display: flex;
    justify-content: center;
  }
  .spinner-table-searcher {
    margin-top: 10em;
    height: 6em;
    width: 6em;
    color: #acacac;
  }
  .background-left-proposal {
    width: 10px;
    height: 220px;
    position: absolute;
    top: 0;
    left: 0px;
  }
  /* .title-tools{
    font-weight: bolder;
    color: #1ABC9C;
  }
  .title-orders{
    font-weight: bolder;
    color: #9B59B6;
  }
  .title-admin-changes{
    font-weight: bolder;
    color: #D68910;
  }
  .title-potential{
    font-weight: bolder;
    color: #EC7063;
  } */
  .default-color-background-tools {
    background: linear-gradient(180deg, #50D0AD 0%, #29809A 100%);
  }
  .default-color-background-orders {
    background: linear-gradient(180deg, #ef98ff 0, #bf83ff 25%, #8f6cd8 50%, #6355b0 75%, #3d3f8b 100%);
  }
  .default-color-background-requests {
    background: radial-gradient(circle at 50% -20.71%, #e7e14a 0, #e4cf42 12.5%, #dcbc3c 25%, #d2a736 37.5%, #c39231 50%, #b37e2d 62.5%, #a46c29 75%, #965c26 87.5%, #894f24 100%);
  }
  .default-color-background-earnings {
    background: radial-gradient(circle at 50% -20.71%, #ff7e6b 0, #ff6e73 12.5%, #ff5d78 25%, #ff4c7a 37.5%, #f23c78 50%, #d52e74 62.5%, #ba2471 75%, #a21d6e 87.5%, #8d1a6c 100%);
  }
  .light-secondary-v2 {
    background-color: #EFF3F6;
    color: #718096;
    font-weight: 400;
  }
  .avatar-proposal-table {
    margin: 0 1em 0 0;
    border-radius: 1em !important;
  }
  #table-searcher-card .card-body {
    padding: 0;
  }
  .table-responsive {
    overflow: visible;
  }
  #table-searcher td {
    border-bottom: 1px solid #eff3f6;
  }
  #table-searcher tr {
    background-color: white;
  }
  #table-searcher tr:hover {
    background-color: #EFF3F6;
    cursor: pointer;
    transition: all 100ms;
  }
  .thead-light th {
    background-color: white !important;
  }
  .checkbox-table-searcher {
    display: none !important;
  }
  #table-searcher tr:hover .checkbox-table-searcher {
    display: block !important;
  }
  #table-searcher tr:hover .id-table-searcher {
    display: none;
  }
  .mr-05 {
    margin-right: 0.5em;
  }
  .card-shadow:hover{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .card-shadow {
    width: 100%;
  }
  .button-spacing {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
  }

  .size-button {
    width: 75%;
    height: 40px;
  }

  .btn-clear {
    width: 20%;
  }

  .btn-clear:focus {
    width: 20%;
    background: transparent !important;
  }
</style>